import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import ProductMainSection from "../../../components/product-main-section"
import Breadcrumb from "../../../components/breadcrumb"
import { getSrc } from "gatsby-plugin-image"
import YouMayLike from "../../../components/you-may-like"

export default function Product({ data }) {
  const product = data.product
  const singleStageData = data.allContentfulSingleStageSeatBeltRepairService.edges
  const dualStageData = data.allContentfulDualStageSeatBeltRepairService.edges
  const tripleStageData = data.allContentfulTripleStageSeatBeltRepairService.edges
  const pretensionerData = data.allContentfulPretensionerService.edges
  const seatBeltColorData = data.allContentfulSeatBeltColoringService.edges
  const seatBeltWebbingData = data.allContentfulSeatBeltWebbingReplacement.edges
  const seatCoverStitchingData = data.allContentfulSeatCoverStitching.edges
  const fullSeatReupholsteringData = data.allContentfulFullSeatReupholstering.edges
  const batteryCableData = data.allContentfulBatteryCableRepairService.edges
  const airbagPlugsData = data.allContentfulAirbagPlugsProduct.edges
  const steeringColumnData = data.allContentfulSteeringColumnSensorRepairService.edges
  const srsAirBagData = data.allContentfulSrsAirbagModuleRepairService.edges
  const convertibleRollBarData = data.allContentfulConvertibleRollBarService.edges
  var smallDesc
  var serviceSlug
  var reviewNumber
  var keyWrd
  var isSeatbeltRepair = false
  var title = product.title.toLowerCase()


  if (title.includes("single")) {
    smallDesc = singleStageData[0].node.servicePageBodyText.childMarkdownRemark.html
    reviewNumber = "550"
    serviceSlug = "/services/seat-belt-repair-service"
    keyWrd = "Seat Belt Service"
    isSeatbeltRepair = true
  }
  else if (title.includes("dual")) {
    smallDesc = dualStageData[0].node.servicePageBodyText.childMarkdownRemark.html
    reviewNumber = "450"
    serviceSlug = "/services/seat-belt-repair-service"
    keyWrd = "Seat Belt Service"
    isSeatbeltRepair = true
  }
  else if (title.includes("triple")) {
    smallDesc = tripleStageData[0].node.servicePageBodyText.childMarkdownRemark.html
    reviewNumber = "370"
    serviceSlug = "/services/seat-belt-repair-service"
    keyWrd = "Seat Belt Service"
    isSeatbeltRepair = true
  }
  else if (title.includes("pretensioner")) {
    smallDesc = pretensionerData[0].node.servicePageBodyText.childMarkdownRemark.html
    serviceSlug = "/services/seat-belt-repair-service"
    reviewNumber = "654"
    keyWrd = "Seat Belt Service"
    isSeatbeltRepair = true
  }
  else if (title.includes("coloring")) {
    smallDesc = seatBeltColorData[0].node.servicePageBodyText.childMarkdownRemark.html
    serviceSlug = "/services/seat-belt-coloring-service"
    reviewNumber = "200"
    keyWrd = "Change Seat Belt Color"
  }
  else if (title.includes("webbing")) {
    smallDesc = seatBeltWebbingData[0].node.servicePageBodyText.childMarkdownRemark.html
    serviceSlug = "/services/seat-belt-webbing-replacement-service"
    reviewNumber = "2204"
    keyWrd = "Change Seat Belt Webbing"
  }
  else if (title.includes("stitching")) {
    smallDesc = seatCoverStitchingData[0].node.servicePageBodyText.childMarkdownRemark.html
    serviceSlug = "/services/seat-cover-stitching-service"
    reviewNumber = "1022"
    keyWrd = "Seat Cover Stitching Repair"
  }
  else if (title.includes("reupholstering")) {
    smallDesc = fullSeatReupholsteringData[0].node.servicePageBodyText.childMarkdownRemark.html
    serviceSlug = "/services/full-seat-reupholstering-service"
    reviewNumber = "4022"
    keyWrd = "SeaFull Seat Reupholstering"
  }
  else if (title.includes("battery")) {
    smallDesc = batteryCableData[0].node.servicePageBodyText.childMarkdownRemark.html
    serviceSlug = "/services/battery-cable-repair-service"
    reviewNumber = "137"
    keyWrd = "Battery Cable Fix"
  }
  else if (title.includes("airbag plug")) {
    smallDesc = airbagPlugsData[0].node.servicePageBodyText.childMarkdownRemark.html
    serviceSlug = "/services/airbag-plugs/airbag-plugs/"
    reviewNumber = "137"
    keyWrd = "Airbag Plugs"
  }
  else if (title.includes("steering")) {
    smallDesc = steeringColumnData[0].node.servicePageBodyText.childMarkdownRemark.html
    serviceSlug = "/services/steering-column-sensor-repair-service"
    reviewNumber = "180"
    keyWrd = "Steering Sensor Fix"
  }
  else if (title.includes("airbag module")) {
    smallDesc = srsAirBagData[0].node.servicePageBodyText.childMarkdownRemark.html
    serviceSlug = "/services/srs-airbag-module-repair-service"
    reviewNumber = "774"
    keyWrd = "Airbag Service"
  }
  else if (title.includes("convertible")) {
    smallDesc = convertibleRollBarData[0].node.servicePageBodyText.childMarkdownRemark.html
    serviceSlug = "/services/convertible-roll-bar-repair-service"
    reviewNumber = "309"
    keyWrd = "Convertible Roll Bar Fix"
  }
  else if (title.includes("headrest")) {
    smallDesc = "Popped out headrest repair using 100% OEM factory parts to restore it to functioning condition."
    serviceSlug = "/services/deployed-headrest-repair-service"
    reviewNumber = "208"
    keyWrd = "Deployed Headrest Fix"
  }
  const getProductType = product.productType
  const makeFirstLetterOfEachWordUppercase = getProductType.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())

  let keywords = [product.title, product.productType, keyWrd]
  let isProductPage = true

  const {
    images: [firstImage]
  } = product

  return (
    <Layout>
      <Seo
        product={product}
        title={product.title + " - Post Collision Specialists"}
        location={product.productTypeSlug}
        keywords={keywords}
        description={product.description}
        isProductPage={isProductPage}
        serviceSlug={serviceSlug}
        // img={getSrc(firstImage.gatsbyImageData)}
        img={firstImage?.gatsbyImageData ? getSrc(firstImage.gatsbyImageData) : ''}
      />
      {/* <nav className="flex container mx-auto px-4 py-3 shadow-4xl" aria-label="Breadcrumb">
        <Breadcrumb links={[
          { to: '/', label: 'Home' },
          { to: '/services', label: 'Services' },
          { to: product.productTypeSlug, label: product.title }
        ]} />
      </nav> */}
      <ProductMainSection
        subTitle={product.productType}
        title={product.title}
        priceRangeV2={product.priceRangeV2}
        description={product.description}
        smallDesc={smallDesc}
        images={product.images}
        variants={product.variants}
        hasOnlyDefaultVariant={product.hasOnlyDefaultVariant}
        options={product.options}
        productTypeSlug={product.productTypeSlug}
        reviewNumber={reviewNumber}
        isSeatbeltRepair={isSeatbeltRepair}
        productMain={product}
        singleStageData={singleStageData}
        dualStageData={dualStageData}
        tripleStageData={tripleStageData}
        pretensionerData={pretensionerData}
        airbagPlugsData={airbagPlugsData}
        seatBeltColorData={seatBeltColorData}
        seatBeltWebbingData={seatBeltWebbingData}
        seatCoverStitchingData={seatCoverStitchingData}
        fullSeatReupholsteringData={fullSeatReupholsteringData}
        batteryCableData={batteryCableData}
        steeringColumnData={steeringColumnData}
        srsAirBagData={srsAirBagData}
        convertibleRollBarData={convertibleRollBarData}
      />
      <YouMayLike
        singleStageData={singleStageData}
        dualStageData={dualStageData}
        tripleStageData={tripleStageData}
        pretensionerData={pretensionerData}
        seatBeltColorData={seatBeltColorData}
        seatBeltWebbingData={seatBeltWebbingData}
        seatCoverStitchingData={seatCoverStitchingData}
        fullSeatReupholsteringData={fullSeatReupholsteringData}
        batteryCableData={batteryCableData}
        steeringColumnData={steeringColumnData}
        srsAirBagData={srsAirBagData}
        convertibleRollBarData={convertibleRollBarData}
        airbagPlugsData={airbagPlugsData}
      />
    </Layout>
  )
}
export const query = graphql`
  query($id: String!, $productType: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      description
      productType
      productTypeSlug: gatsbyPath(
        filePath: "/services/{ShopifyProduct.productType}/{ShopifyProduct.handle}"
      )
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        altText
        id
        gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
        originalSrc
        product{
          title
        }
      }
      hasOnlyDefaultVariant
      variants {
        displayName
        position
        availableForSale
        storefrontId
        title
        price
        selectedOptions {
          name
          value
        }
        image {
          gatsbyImageData
        }
      }
      options {
        name
        values
        id
        position
      }
    }
    suggestions: allShopifyProduct(
      limit: 3
      filter: { productType: { eq: $productType }, id: { ne: $id } }
    ) {
      nodes {
        ...ProductCard
      }
    }
    allContentfulSingleStageSeatBeltRepairService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulDualStageSeatBeltRepairService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulTripleStageSeatBeltRepairService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPretensionerService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulSeatBeltColoringService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulSeatBeltWebbingReplacement {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }  
    allContentfulSeatCoverStitching {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }  
    allContentfulFullSeatReupholstering {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }    
    allContentfulBatteryCableRepairService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulSteeringColumnSensorRepairService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulSrsAirbagModuleRepairService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
      allContentfulAirbagPlugsProduct {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulConvertibleRollBarService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          } 
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`